.leaflet-container {
  width: 100%;
  min-height: calc(100vh - 70px);
  position: relative;
  z-index: 1;
}

.tooltip-container {
  background-color: #fff;
  border-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 4px rgba(86, 105, 129, 0.2);
  padding: 1em;
  opacity: 1;
}
